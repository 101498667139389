import "./App.css";
import Logo from "./assets/logoBlack.svg";
import ScreenImg from './assets/screen.png';
import Background from './assets/background.jpg';
import { useNavigate } from 'react-router-dom';

function Terms() {

  const navigate = useNavigate()

  return (
    <>
    <div
      className="Policy"
      style={{
        display: "flex",
        justifyContent: 'flex-start',
        paddingBlock:60,
        flexWrap:'wrap',
        backgroundImage:`url(${Background})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
      }}
    >
      <div
      className="container1"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems:'flex-start',
          maxWidth:'50%',
        }}
      >
        <img src={Logo} onClick={()=>{navigate('/')}} alt="logo" width={100} height={40} />
        <div style={{ maxWidth: "800px", margin: "0 auto",  fontFamily: "Arimo, sans-serif" }}>
      <h1 style={{ textAlign: "left", fontSize: "60px", marginTop: "12%" }}>
        Terms of Use
      </h1>
      
      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "20px" }}>
        By downloading, installing, or using the "Test Theory of Driving Israel SkillWheel" app, you agree to these Terms of Use. If you do not agree to these terms, please do not use the app.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Intellectual Property Rights
      </h2>
      
      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        All content, features, and functionality within the app, including but not limited to text, graphics, logos, icons, images, software, and audio, are owned by us and are protected by international copyright, trademark, and other intellectual property laws.
      </p>
      
      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        You may not copy, reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material within the app, except as expressly permitted by us in writing.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        License Grant
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We grant you a limited, non-exclusive, non-transferable, revocable license to download, install, and use the app on a mobile device that you own or control for personal, non-commercial purposes only, subject to your compliance with these Terms of Use.
      </p>
      
      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Prohibited Uses
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        You agree not to:
      </p>
      
      <ul style={{ textAlign: "left", fontSize: "20px", marginLeft: "20px" }}>
        <li>Copy, modify, distribute, or create derivative works based on the app or any part of it.</li>
        <li>Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code of the app.</li>
        <li>Use the app for any illegal or unauthorized purpose.</li>
        <li>Attempt to access, hack, or tamper with any of the app's functionality or data.</li>
        <li>Use the app in any manner that could damage, disable, overburden, or impair the app’s functionality or interfere with any other party’s use of the app.</li>
      </ul>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Subscription and Payments
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        The app may offer auto-renewing subscriptions for premium features. By purchasing a subscription, you agree to the subscription terms provided within the app and to pay the applicable subscription fees. You are responsible for managing your subscription through your account settings and ensuring that auto-renewal is turned off if you wish to cancel the subscription.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Termination
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We reserve the right to terminate or suspend your access to the app, with or without notice, for any reason, including but not limited to your violation of these Terms of Use or any applicable law. Upon termination, your right to use the app will immediately cease.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Disclaimer of Warranties
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        The app is provided on an "as is" and "as available" basis, without warranties of any kind. We do not warrant that the app will be uninterrupted, error-free, secure, or that any defects will be corrected.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Limitation of Liability
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        To the fullest extent permitted by law, we shall not be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising out of your use or inability to use the app.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Changes to Terms of Use
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We may update or modify these Terms of Use at any time. Your continued use of the app following any changes means that you accept and agree to the revised terms.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Contact Us
      </h2>

      <p style={{ textAlign: "left", marginBottom:100, fontSize: "20px", marginTop: "10px" }}>
        If you have any questions about these Terms of Use, please contact us at <strong>info@skillwheel.co.il</strong>.
      </p>
    </div>
      </div>

      <div 
      className="imagehand"
      style={{
        backgroundImage:`url(${ScreenImg})`,
        height:'100%',
        width:'50%',
        alignSelf:'flex-end',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat'
      }}>
      </div>
    </div>
    
    <div style={{
      height:60, 
      width:'100%',
      position:'fixed',
      bottom:0,
      backgroundColor:'#FF621A',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    }}>
      <p style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
        contact us -  
      </p>
      <a href="mailto:info@skillwheel.co.il?subject=Website%20support" style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
         info@skillwheel.co.il
      </a>
    </div>
    </>
  );
}

export default Terms;
