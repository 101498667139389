import "./App.css";
import Logo from "./assets/logoBlack.svg";
import ScreenImg from './assets/screen.png'
import Background from './assets/background.jpg'
import { useNavigate } from 'react-router-dom';

function Policy() {

  const navigate = useNavigate()

  return (
    <>
    <div
      className="Policy"
      style={{
        display: "flex",
        justifyContent: 'flex-start',
        paddingBlock:60,
        flexWrap:'wrap',
        backgroundImage:`url(${Background})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
      }}
    >
      <div
      className="container1"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems:'flex-start',
          maxWidth:'50%',
          // marginRight:'10%',
          // marginLeft:120,
        }}
      >
        <img src={Logo} onClick={()=>{navigate('/')}} alt="logo" width={100} height={40} />
        <div style={{ maxWidth: "800px", margin: "0 auto",  fontFamily: "Arimo, sans-serif" }}>
      <h1 style={{ textAlign: "left", fontSize: "60px", marginTop: "12%" }}>
        Privacy Policy
      </h1>
      
      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "20px" }}>
        Welcome to the privacy policy of "Test Theory of Driving Israel SkillWheel". We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and share your personal information when you use our app, as well as your rights regarding that information. By using our app, you agree to the terms of this Privacy Policy.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Information We Collect
      </h2>
      
      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We collect the following types of information when you use our app:
      </p>
      
      <ul style={{ textAlign: "left", fontSize: "20px", marginLeft: "20px" }}>
        <li><strong>Personal Information:</strong> Name, email address, and any other information you provide when registering or using the app.</li>
        <li><strong>Payment Information:</strong> Details required for processing subscriptions and payments through your iTunes account.</li>
        <li><strong>Usage Data:</strong> Information about how you use the app, including device information, app interaction, and error logs.</li>
      </ul>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        How We Use Your Information
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We use the information we collect for the following purposes:
      </p>
      
      <ul style={{ textAlign: "left", fontSize: "20px", marginLeft: "20px" }}>
        <li>To provide and maintain the app.</li>
        <li>To process payments and manage subscriptions.</li>
        <li>To improve the app’s performance, features, and user experience.</li>
        <li>To analyze app usage and trends.</li>
      </ul>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Subscriptions and Payments
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        Our app offers auto-renewing subscriptions for premium features. Payment will be charged to your AppStore Account upon confirmation of purchase. Subscriptions automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period at the cost of the chosen subscription package. You can manage your subscriptions and turn off auto-renewal by going to your Account Settings after purchase. Any unused portion of a free trial period, if offered, will be forfeited when you purchase a subscription.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        How We Share Your Information
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We may share your information with the following third parties:
      </p>

      <ul style={{ textAlign: "left", fontSize: "20px", marginLeft: "20px" }}>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us with payment processing, analytics, and app maintenance.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law, legal process, or governmental request.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, sale, or transfer of company assets, your information may be transferred to the new owner.</li>
      </ul>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Data Security
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet is completely secure.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Your Rights
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        You have the following rights regarding your personal data:
      </p>

      <ul style={{ textAlign: "left", fontSize: "20px", marginLeft: "20px" }}>
        <li><strong>Access and Correction:</strong> You have the right to access the personal information we hold about you and request correction if necessary.</li>
        <li><strong>Deletion:</strong> You have the right to request the deletion of your personal data, subject to certain legal obligations.</li>
        <li><strong>Opt-out:</strong> You can opt-out of receiving promotional communications by following the unsubscribe instructions provided in our emails.</li>
      </ul>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        To exercise any of these rights, please contact us at <strong>info@skillwheel.co.il</strong>.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Cookies and Tracking Technologies
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We use cookies and similar tracking technologies to improve your experience, analyze usage, and enhance app functionality. You can control the use of cookies through your device settings, but disabling cookies may affect the app's functionality.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Changes to This Privacy Policy
      </h2>

      <p style={{ textAlign: "left", fontSize: "20px", marginTop: "10px" }}>
        We may update this Privacy Policy from time to time. When we do, we will notify you by updating the "Last Updated" date at the top of this Privacy Policy. We recommend reviewing this policy periodically to stay informed about how we are protecting your information.
      </p>

      <h2 style={{ textAlign: "left", fontSize: "40px", marginTop: "30px" }}>
        Contact Us
      </h2>

      <p style={{ textAlign: "left", marginBottom:100, fontSize: "20px", marginTop: "10px" }}>
        If you have any questions about this Privacy Policy or your personal information, please contact us at <strong>info@skillwheel.co.il</strong>.
      </p>
    </div>

        




      </div>

      <div 
      className="imagehand"
      style={{
        backgroundImage:`url(${ScreenImg})`,
        height:'100%',
        width:'50%',
        alignSelf:'flex-end',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat'
      }}>

      </div>

      
    </div>
    <div style={{
      height:60, 
      width:'100%',
      position:'fixed',
      bottom:0,
      backgroundColor:'#FF621A',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    }}>
      <p style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
        contact us -  
      </p>
      <a href="mailto:info@skillwheel.co.il?subject=Website%20support" style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
         info@skillwheel.co.il
      </a>
    </div>
    </>
  );
}

export default Policy;
